import React, {useEffect, useRef, useState} from "react";
import DraggableButton from "./DraggableButton";
import {PageType} from "../../utils/PageType";
import {useTranslation} from "react-i18next";
import {REGION_LOCK_ISO} from "../../utils/options";
import {redirect} from "react-router-dom";
import Sheet, {SheetRef} from "react-modal-sheet";
import {analytics} from "../../utils/firebase";
import {logEvent} from "firebase/analytics";
import {AnimatePresence, motion} from "framer-motion";
import Lottie from "lottie-react";
import lottieVerified from "../../animations/lottie_verified.json";
import lottieLoading from "../../animations/lottie_loading.json";

interface WelcomeComponentProps {
    setPageType: (pageType: PageType) => void;
}

const WelcomeComponent : React.FC<WelcomeComponentProps> = ({ setPageType }) => {

    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(true);
    const ref = useRef<SheetRef>();
    const snapTo = (i: number) => ref.current?.snapTo(i);

    const [tos, setTOS] = useState<boolean>(false);

    const fetchIPData = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if(!response.ok) {
                window.location.href = "/locked";
            }

            const jsonedResponse = await response.json();

            if(REGION_LOCK_ISO!.split(",").find(r => r == jsonedResponse.country_code) == undefined) {
                window.location.href = "/locked";
            } else {
                setLoading(false)
            }
        } catch (error) {
            window.location.href = "/locked";
        }
    };

    useEffect(() => {
        if(REGION_LOCK_ISO != undefined) {
            fetchIPData();
        } else {
            setLoading(false);
        }
        logEvent(analytics, "page_view", {
            page_title: "Welcome"
        });
    })

    if(isLoading) {
        return null;
    }

    return (
        <div className={"w-screen flex flex-col items-center"}>
            <div className={"flex flex-row items-center gap-8 mt-[10%]"}>
                <img src={"./assets/images/logo-orange.svg"} alt={"simly-logo"} className={"w-28"}/>
            </div>
            <AnimatePresence mode={"wait"}>
                <motion.div
                    className={"w-screen flex flex-col items-center"}
                    key={"sheet"}
                    initial={{opacity: 1, y: 1000}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 0}}
                    transition={{duration: 0.6}}
                >
                    <div className="w-[82.5%] mt-[7.5%] flex flex-col items-center rounded-3xl bg-[#242C38]">
                        <img src={"./assets/images/gifts.png"} alt={"gifts"} className={"mt-[7.5%]"}/>
                        <p className={"font-shahid-tt-bold text-white text-4xl"}>{t('freeSim')}</p>
                        <div className={"flex flex-col items-center justify-center mt-[2%]"}>
                            <p className={"font-gilroy-semibold text-white text-sm"}>{t('freeSimFirstLine')}</p>
                            <p className={"font-gilroy-semibold text-white text-sm"}>{t('freeSimSecondLine')}</p>
                        </div>
                        <div className={"flex flex-col items-center justify-center mt-[10%] mb-[10%]"}>
                            <p className={"font-gilroy-semibold text-white text-xs"}>{t('poweredBy')}</p>
                            <img src={"./assets/images/logo.svg"} alt={"simly-logo"} className={"w-12 mt-[10%]"}/>
                        </div>
                    </div>
                </motion.div>
                <motion.div className={"w-[75%] mt-[7.5%]"} key={"redeem"}
                            initial={{opacity: 1, y: 1000}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 0}}
                            transition={{delay: 0.6, duration: 0.5}}
                >
                    <div
                        className={"w-100 h-16 bg-[#242C38] relative rounded-2xl flex flex-row items-center justify-center"}
                        onClick={() => {
                            logEvent(analytics, 'click', {button: 'redeemNow'});
                            setPageType(PageType.EMAIL)
                        }}>
                        <div className={"w-[70%] flex flex-row items-center justify-center"}>
                            <p className={"font-shahid-tt-medium text-lg text-white"}>{t('redeemNow')}</p>
                        </div>
                    </div>
                </motion.div>
                <motion.p className={"text-base text-[#242C38] font-gilroy-semibold underline my-[5%]"}
                          key={"redeem"}
                          initial={{opacity: 1, y: 1000}}
                          animate={{opacity: 1, y: 0}}
                          exit={{opacity: 0, y: 0}}
                          transition={{delay: 1.1, duration: 0.5}}
                          onClick={() => {
                    setTOS(true)
                    logEvent(analytics, 'click', {link: 'termsAndConditions'})
                }}>{t('termsAndConditions')}</motion.p>
            </AnimatePresence>

            <Sheet
                ref={ref}
                isOpen={tos}
                onClose={() => setTOS(false)}
                snapPoints={[800]}
                initialSnap={0}
                onSnap={snapIndex =>
                    console.log('> Current snap point index:', snapIndex)
                }
            >
                <Sheet.Container>
                    <Sheet.Header></Sheet.Header>
                    <Sheet.Content style={{paddingBottom: ref.current?.y}}>
                        <Sheet.Scroller draggableAt="both">
                            <div
                                className={"w-screen h-screen bg-white flex flex-col items-start gap-5 mt-2 mb-4 overflow-auto font-gilroy-regular px-[5%] text-justify"}>
                                <p className={"text-xl underline"}>
                                    {t('freeSim')}
                                </p>
                                <p>
                                    {t('termsAndConditionsIntroduction')}
                                </p>
                                <p>
                                    <span className={"underline"}>{t('termsAndConditionsEntryTitle')}</span>
                                    <br></br>
                                    {t('termsAndConditionsEntryFirstSection')}
                                    <br></br>
                                    {t('termsAndConditionsEntryDisqualify')}
                                    <br></br>
                                </p>
                                <p>
                                    <span className={"underline"}>{t('promotionProceduresTitle')}</span>
                                    <br></br>
                                    {t('promotionProcedure1')}
                                    <br></br>
                                    {t('promotionProcedure2')}
                                    <br></br>
                                    {t('promotionProcedure3')}
                                    <br></br>
                                </p>
                                <p>
                                    <span className={"underline"}>{t('validityTitle')}</span>
                                    <br></br>
                                    {t('validity1')}
                                    <br></br>
                                    {t('validity2')}
                                    <br></br>
                                </p>
                                <p>
                                    <span className={"underline"}>{t('cancellationsTitle')}</span>
                                    <br></br>
                                    {t('cancellation1')}
                                    <br></br>
                                    {t('cancellation2')}
                                    <br></br>
                                    {t('cancellation3')}
                                    <br></br>
                                    {t('cancellation4')}
                                    <br></br>
                                    {t('cancellation5')}
                                    <br></br>
                                </p>
                                <p>
                                    <span className={"underline"}>{t('standardTermsTitle')}</span>
                                    <br></br>
                                    {t('standardTerm1')}
                                    <br></br>
                                    {t('standardTerm2')}
                                    <br></br>
                                    {t('standardTerm3')}
                                    <br></br>
                                    {t('standardTerm4')}
                                    <br></br>
                                    {t('standardTerm5')}
                                    <br></br>
                                </p>
                            </div>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
            </Sheet>
        </div>
    )
}

export default WelcomeComponent;
