import {PageType} from "../../utils/PageType";
import React, {useEffect} from "react";
import {UserInfo} from "../../dto/UserInfo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {CampaignService} from "../../services/CampaignService";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../utils/firebase";

interface EmailComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const EmailComponent : React.FC<EmailComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_title: "Email"
        });
    }, []);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const { t } = useTranslation();

    const confirmEmail = async () => {
        try {
            toast.dismiss();

            const isValidEmail = emailRegex.test(userInfo.email);

            if(!isValidEmail) {
                toast.error(t('emailIncorrect'), {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            const isEmailRedeemed = await CampaignService.isEmailRedeemed(userInfo.email);

            if(isEmailRedeemed) {
                toast.error("This email has already been redeemed!");
                return;
            }

            logEvent(analytics, 'click', { button: 'emailConfirm' });
            logEvent(analytics, 'sign_up', { email: userInfo.email });
            setPageType(PageType.REDEEMED);
        } catch (e) {
            toast.error((e as Error).message);
        }

    }

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                <div className={"w-7 h-7 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.WELCOME)}>
                    <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                </div>
                <div className={"flex flex-col mt-[7.5%] w-full"}>
                    <p className={"font-gilroy-bold text-3xl text-black"}>{t('enterEmailHeader')}</p>
                    <p className={"font-gilroy-semibold text-lg text-[#BFBFB9] mt-[1%]"}>{t('enterEmailDescription')}</p>
                    <input
                        className={"mt-[7.5%] text-2xl text-black font-gilroy-bold outline-none placeholder-[#BFBFB9] w-full"}
                        onChange={(e) => setUserInfo({
                            ...userInfo,
                            email: e.target.value!.trim()
                        })}
                        value={userInfo.email}
                        placeholder={t('enterEmail')}
                        type={"email"}
                        autoFocus
                    />
                </div>
                <div className={"mt-[15%] w-full flex flex-row justify-center items-center"}>
                    <div className={"flex flex-row items-center justify-center w-52 h-14 rounded-xl bg-[#242C38] select-none"} onClick={() => confirmEmail()}>
                        <p className="font-gilroy-semibold text-xl text-white">
                            {t('nextButton')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailComponent;
