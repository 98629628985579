import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Lottie from "lottie-react";
import alreadyRedeemedAnimation from "../animations/lottie_already_redeemed.json";

const AlreadyRedeemed: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="h-screen w-full flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center text-center">
                <Lottie className={"w-[80%]"} animationData={alreadyRedeemedAnimation} loop={true}/>
                <h1 className="w-[90%] text-3xl font-extrabold text-gray-800">{t('alreadyRedeemed')}</h1>
                <h2 className="mt-5 w-[40%] text-xl font-bold text-gray-700">{t('alreadyRedeemedExplanation')}</h2>
            </div>
        </div>
    );
};

export default AlreadyRedeemed;
