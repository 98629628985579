import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDp5jTMniz6dyaSVXpuigs88S3k0nealOM",
    authDomain: "simly-fly-campaign.firebaseapp.com",
    projectId: "simly-fly-campaign",
    storageBucket: "simly-fly-campaign.appspot.com",
    messagingSenderId: "589342699914",
    appId: "1:589342699914:web:9adbc610713687c37ad873",
    measurementId: "G-CTT9SJNRBF"
};

export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const analytics = getAnalytics(app);
