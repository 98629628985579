export interface PlanDTO {
    planId: string;
    duration?: number;
    size?: number;
    price: number;
    currency: string;
    activationPolicy: string;
    planType: PlanType;
    supported_countries: CountryNetworks[];
    isManualAPNRequired: boolean;
    isKYCRequired: boolean;
    apn: string;
    color?: string;
}

export enum PlanType {
    DATAONLY = "DATAONLY",
    UNLIMITED = "UNLIMITED"
}

export interface Network {
    name: string;
    _3G: boolean;
    LTE: boolean;
    _5G: boolean;
    code: string;
}

export interface CountryNetworks {
    isoCode: string;
    countryImageURL: string;
    name: string;
    supported_networks: Network[];
}

